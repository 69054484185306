import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist';
import { LOGIN_USER } from '../graphql/mutation'
import { client } from '../main.js'

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

function initialState () {
  return {
    appName: 'Partner Dashboard',
    logo: require('../assets/images/favicon.png'),
    img: require('../assets/images/logo-dark.png'),
    dark: false,
    namespaced: true,
    token: null,
    user:{},
    authStatus: false,
    errors: [],
    orderFilters: {
      sort_by:'SCHEDULED_AT - DESC',
      filter_date_lte:null,
      filter_date_gte:null,
      filter_date_lte_quoted:null,
      filter_date_gte_quoted:null,
      status: null,
      isQuoted: null,
      isQuoteRequired: null,
      workshop: null,
      hasApprovedQuotation: null,
      // orderSearch:null
    },

    jobsFilters:{
      status: null,
      isActive:null,
      isCompleted:null,
      jobTypes:[],
      jobStatusData:[]
    },
    jobTimelineFilters:{
      status: null,
      isActive:null,
      isCompleted:null,
      jobTypes:[],
      jobStatusData:[]
    },
  }
}

export default new Vuex.Store({
  state: initialState,
  plugins: [vuexLocal.plugin],
  mutations: {
    layoutModeCommit (state, payload) {
      state.dark = payload
      if (!payload) {
        state.logo = require('../assets/images/logo.png')
        state.img = require('../assets/images/logo-dark.png')
      } else {
        state.logo = require('../assets/images/logo.png')
        state.img = require('../assets/images/logo.png')
      }
    },
    orderFilterCommit (state, payload) {
      state.orderFilters = payload
    },
    jobsFilterCommit (state, payload) {
      state.jobsFilters = payload
    },
    jobTimelineFilterCommit (state, payload) {
      state.jobTimelineFilters = payload
    },
    SET_TOKEN (state, token){
      state.token = token
    },
    LOGIN_USER (state, user){
      state.authStatus = true
      state.user = { ...user }
    },
    LOGOUT_USER(state){
      state.authStatus = ''
      state.token = '' && localStorage.removeItem('apollo-token')
    },
    RESET_STATE (state) {
      // acquire initial state
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  actions: {
    layoutModeAction (context, payload) {
      context.commit('layoutModeCommit', payload.dark)
    },
    orderFilterAction (context, payload) {
      context.commit('orderFilterCommit', payload.orderFilters)
    },
    jobsFilterAction (context, payload) {
      context.commit('jobsFilterCommit', payload.jobsFilters)
    },
    jobTimelineFilterAction (context, payload) {
      context.commit('jobTimelineFilterCommit', payload.jobTimelineFilters)
    },
    async login({ commit },{vm, authDetails}){
        try{
     
          await client.mutate({
              mutation: LOGIN_USER,
              fetchPolicy: 'no-cache',
              variables: { ...authDetails },
             
          }).then(data =>{
              if(data.data.tokenCreate.errors.length === 0){
                const token = data.data.tokenCreate.token
                const userDetails = JSON.stringify(data.data.tokenCreate.user)
                commit('SET_TOKEN', token)
                commit('LOGIN_USER', userDetails)
                localStorage.setItem('apollo-token', token)
                localStorage.setItem('user-details', userDetails);
                if(data.data.tokenCreate.user.hasWorkshopAccess){
                  vm.$router.push({name: 'layout.order'})
                }
                else{
                  vm.$router.push({name: 'error.401'})
                }
                // else{
                //   vm.$bvToast.toast(`Permission denied! As the user does not belong to any job profile.`, {
                //     toaster: "b-toaster-top-center",
                //     variant: "danger",
                //     solid: true,
                //     toastClass: "text-center toast-box toast-style",
                //     // noAutoHide: true
                //   });
                // }
              } else{
                const message = data.data.tokenCreate.errors.map(err => {
                      return err.message;
                  })

                  vm.$bvToast.toast(`${message}`, {
                    toaster: "b-toaster-top-center",
                    variant: "danger",
                    solid: true,
                    toastClass: "text-center toast-box toast-style",
                    // noAutoHide: true
                  });
                
              
              }
          })
       
        }catch(e){
          console.log('pp'+e)
        }
    },
    async logOut ({ commit }) {
      commit('LOGOUT_USER')
      commit('RESET_STATE')
      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('apollo-token');
        localStorage.removeItem('user-details');
        localStorage.removeItem("jobsFilters");
      }
    }
  },
  getters: {
    appName: state => { return state.appName },
    logo: state => { return state.logo },
    img: state => { return state.img },
   
    dark: state => { return state.dark },
    isAuthenticated: state => { return !!state.token },
    authStatus: state =>state.authStatus,
    user: state =>state.user,
    orderFilters: state => { return state.orderFilters },
    jobsFilters: state => { return state.jobsFilters },
    jobTimelineFilters: state => { return state.jobTimelineFilters },
  },
  modules: {
  }
})

