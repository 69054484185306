import gql from 'graphql-tag'

export const LOGIN_USER = gql`
mutation tokenCreate($email: String! $password: String!){
  tokenCreate(email: $email, password: $password) {
    errors {
      field
      message
    }
    token
    user {
      firstName
      lastName
      phone
      jobProfile {
        type
      }
      hasWorkshopAccess
      canCreateOrders
    }
  }
}
`


export const WORKSHOP_ORDER_ATTACHMENT_UPLOAD = gql`
mutation workshopOrderAttachmentUpload(
  $id: ID!, $files: [Upload!]!, $titles: [String!]
){
  workshopOrderAttachmentUpload(input: {
    id:$id, files: $files, titles: $titles
  }){
    errors{
      field
      message
    }
    order{
      number
      status
      workshopQuotedAt
      car{
        model{
          id
          name
          make{
            id
            name
          }
        }
      }
      scheduledAt
      lines{
        service{
          id
          type{
            name
            description
          }
        }
        serviceName
      }
    }
  }
}`

export const WORKSHOP_ORDER_UPDATE = gql`
mutation workshopOrderUpdate(
  $id: ID!, 
  $action: String, 
  $sendToTrello: Boolean,
  $labourHours: Decimal,
  $labourPrice: Decimal,
  $workshopExpectedCompletionDays: Int,
  $comment: String,
){
  workshopOrderUpdate(
    id:$id, 
    action: $action, 
    sendToTrello: $sendToTrello, 
    labourHours: $labourHours, 
    labourPrice: $labourPrice, 
    workshopExpectedCompletionDays: $workshopExpectedCompletionDays,
    comment: $comment
  ){
    errors{
      field
      message
    }
    order{
      number
      status
      car{
        model{
          id
          name
          make{
            id
            name
          }
        }
        licensePlate
        year
      }
      scheduledAt
      workshopQuotedAt
      workshopAssignedAt
      workshopAcceptedBy{
        firstName
        lastName
      }
      workshopExpectedCompletionAt
      labourHours
      labourRatePerHour{
        amount
        currency
      }
      workshopExpectedCompletionDays
      labourPrice{
        amount
        currency
      }
      canSendToTrello
      assignedTo{
        firstName
        lastName
        phone
      }
      lines{
        service{
          id
          type{
            name
            description
          }
        }
        serviceName
      }
      partQuotations{
        id
        partName
        priceSupplier{
          currency
          amount
        }
        unitPriceSupplier{
          amount
          currency
        }
        quantity
        quality
        availability
        status
        createdAt
        createdBy{
          firstName
          lastName
        }
        partNumber
        brand
      }
      staffNote
      valetNote
      isQuoteRequired
      workshopTrackingPdfUrl
      attachments{
        file
        filename
        extension
        uploadedAt
        uploadedBy{
          firstName
        }
      }
      approvedQuotation
      comments{
        comment
        createdAt
        createdBy{
          firstName
          lastName
        }
      }
    }
  }
}`

export const ADD_WORKSHOP_USER = gql`
mutation WorkshopUserCreate($phone: String!, $firstName: String!, $lastName: String, $email: String, $referrer: String) {
  workshopUserCreate(input: {phone: $phone, firstName: $firstName, lastName: $lastName, email: $email, referrer: $referrer}) {
    errors {
      field
      message
    }
    user {
      id
      firstName
      lastName
      email
      phone
    }
  }
}`

export const ADD_WORKSHOP_USER_CAR = gql`
mutation WorkshopUserCarCreate($userId: String!, $model: ID!, $year: Int!, $licensePlate: String!, $vin: String, $registrationCardFront: Upload, $registrationCardBack: Upload) {
  workshopUserCarCreate(input: {userId: $userId, model: $model, year: $year, licensePlate: $licensePlate, vin: $vin, registrationCardFront: $registrationCardFront, registrationCardBack: $registrationCardBack}) {
    errors {
      field
      message
    }
    car {
      id
      model {
        name
        make {
          name
        }
      }
      year
      licensePlate
    }
  }
}`

export const WORKSHOP_ORDER_CREATE = gql`
mutation WorkshopOrderCreate($customer: String!, $car: ID!, $workshop: ID!, $services: [OfflineOrderServiceInput!]!, $paymentMethod: ID!, $total: Decimal!, $staffNote: String) {
  workshopOrderCreate(input: {customer: $customer, car: $car, workshop: $workshop, services: $services, paymentMethod: $paymentMethod, total: $total, staffNote: $staffNote}) {
      errors {
          field
          message
      }
      order {
          number
          status
          car {
              model {
                  id
                  name
                  make {
                    id
                  }
              }
          }
          canSendToTrello
          isQuoteRequired
          lines {
              serviceName
              price {
                  currency
                  amount
              }
          }
      }
  }
}`

export const WORKSHOP_PART_QUOTATION_CREATE = gql`
mutation workshopPartQuotationsCreate(
  $orderId: ID!,
  $input: [WorkshopPartQuotationCreateInput!]!,
  $isFullyQuoted: Boolean,
){
  workshopPartQuotationsCreate(
    orderId: $orderId,
    input: $input,
    isFullyQuoted: $isFullyQuoted
  ) {
    errors{
      field
      message
    }
    isFullyQuoted
    partQuotations{
      id
      partName
      priceSupplier{
        amount
        currency
      }
      availability
      quality
      quantity
      partNumber
      brand
      status
      createdBy{
        firstName
        lastName
      }
      createdAt
      unitPriceSupplier{
        amount
        currency
      }
    }
  }
}`

export const WORKSHOP_PART_QUOTATION_DELETE = gql`
mutation workshopPartQuotationDelete(
  $id: ID!
){
  workshopPartQuotationDelete(
    id: $id
  ) {
    errors{
      field
      message
    }
    partQuotation{
      id
      partName
      priceSupplier{
        amount
        currency
      }
      availability
      quality
      quantity
      partNumber
      brand
      status
      createdBy{
        firstName
        lastName
      }
      createdAt
      unitPriceSupplier{
        amount
        currency
      }
    }
  }
}`

export const JOB_UPDATE = gql `
mutation jobUpdate($id: ID!, $action: JobStatusEnum!){
  jobUpdate(jobId: $id, action: $action){
    errors{
      field
      message
    }
    job{
      id
      status
    }
  }
}`


export const WORKSHOP_JOB_CREATE = gql`
mutation WorkshopJobCreate($orderId: ID!, $type: JobTypeEnum!) {
  workshopJobCreate(orderId: $orderId, type: $type) {
    errors {
      field
      message
    }
    job {
      id
      type
      status
      assignedTo {
        phone
        firstName
      }
    }
  }
}`


export const WORKSHOP_JOB_UPDATE = gql`
mutation WorkshopJobUpdate($jobId: ID!, $assignedTo: String, $scheduledAt: DateTime, $scheduledUpto: DateTime, $unschedule: Boolean, $notes: String, $hoursRequired: Decimal) {
  workshopJobUpdate(jobId: $jobId, assignedTo: $assignedTo, scheduledAt: $scheduledAt, scheduledUpto: $scheduledUpto, unschedule: $unschedule, notes: $notes, hoursRequired: $hoursRequired) {
    errors {
      field
      message
    }
    job {
      id
      status
      assignedTo {
        phone
        firstName
      }
    }
  }
}`
